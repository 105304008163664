import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Countries',
  slug: '/countries/',
  title: 'Countries',
}

export const query = graphql`
  query CountriesPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "countries.jpg" }) { ...ImageMain }
    results: postgres {
      countries: allCountriesList(
        filter: { genders: { contains: [ $genderId ] }},
        orderBy: NAME_ASC,
      ) {
        ...Country
      }
    }
  }
`

export default ({ data }) => {
  const { countries } = data.results
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Countries'
  const title = `${entity.singular} ${page}`
  const description = `
    Every culture and region in the world developed their own rudimentary
    perfumery throughout history. These traditions, passed down through
    time, have left each nation with a unique approach to fragrance.
    Some countries, with plentiful raw materials, and years of cultural
    know-how, are known for their iconic and serious purism. Other nations,
    without much historical experience, excel at modern sourcing and
    production methods.
  `
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: page,
      slug: links.countries,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: title,
    places: countries,
    slug: links.countries,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Image
        alt={title}
        credit="countries.jpg"
        fixed={imageMain}
      />

      <Paragraph>
        {description}
      </Paragraph>

      <List>
        {countries.map(country => (
          <ListItem key={country.id}>
            <Link href={country.slug}>
              {country.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
